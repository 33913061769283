import React from "react";
const AboutUs = () => {
  return (
    <section id="about" className="section">
      <div className="container">
        {/* Heading */}
        <p className="text-center mb-2 wow fadeInUp">
          <span className="bg-primary text-dark px-2">About Me</span>
        </p>
        <h2 className="text-10 fw-600 text-center mb-5 wow fadeInUp">
          Software and Beyond
        </h2>
        {/* Heading end*/}
        <div className="row">
          <div className="col-lg-8 text-center text-lg-start wow fadeInUp">
            <h2 className="text-8 fw-400 mb-3">
              Hi, I'm{" "}
              <span className="fw-700 border-bottom border-3 border-primary">
                Mo Zahid
              </span>
            </h2>
            <p className="text-5">
              I’m a Technical Product Manager with a background in software engineering and a passion for building scalable, user-centric products. 
              Most recently, I led the development of a Cyber Quote Generator product, an underwriting automation platform that simplifies risk assessment for cyber insurance.  
              I specialize in product strategy, market validation, and technical execution, bridging the gap between business needs and engineering solutions.
            </p>
            <p className="text-5">
              I thrive at the intersection of technology, business, and user experience, ensuring that products are not only functional but also drive impact. 
              Whether it’s defining roadmaps, collaborating with cross-functional teams, or optimizing workflows, I’m focused on delivering solutions that scale.
            </p>
            <p className="text-5">
              Outside of product and technology, I’m a strong advocate for meditation and mindfulness. 
              I’ve helped tens of thousands of people explore meditation programs online, and I believe that clarity, focus, and calm decision-making are essential in both life and leadership.
            </p>
          </div>
          <div
            className="col-lg-4 mt-4 mt-lg-0 wow fadeInUp"
            data-wow-delay="0.2s"
          >
            <div className="featured-box style-4">
              <div className="featured-box-icon text-25 fw-500 bg-primary rounded-circle">
                <span className="wow heartBeat" data-wow-delay="1.3s">
                  2
                </span>
              </div>
              <h3 className="text-7 wow rubberBand" data-wow-delay="2s">
                Years of <span className="fw-700">Roadmaping</span>
              </h3>
            </div>
            <div className="featured-box style-4">
              <div className="featured-box-icon text-25 fw-500 bg-primary rounded-circle">
                <span className="wow heartBeat" data-wow-delay="1.3s">
                  2
                </span>
              </div>
              <h3 className="text-7 wow rubberBand" data-wow-delay="2s">
                Years of Dev <span className="fw-700">Experiance</span>
              </h3>
            </div>
            <div className="featured-box style-4">
              <div className="featured-box-icon text-25 fw-500 bg-primary rounded-circle">
                <span className="wow heartBeat" data-wow-delay="1.3s">
                  2
                </span>
              </div>
              <h3 className="text-7 wow rubberBand" data-wow-delay="2s">
                Years of Startup <span className="fw-700">Experiance</span>
              </h3>
            </div>
          </div>
        </div>
        <div className="row gy-3 mt-4">
          <div className="col-6 col-lg-3 wow fadeInUp">
            <p className="text-muted fw-500 mb-0">Name:</p>
            <p className="text-4 text-dark fw-600 mb-0">Mo Zahid</p>
          </div>
          <div className="col-6 col-lg-3 wow fadeInUp" data-wow-delay="0.2s">
            <p className="text-muted fw-500 mb-0">Email:</p>
            <p className="text-4 fw-600 mb-0">
              <a className="link-dark" href="mailto:chat@callum.com">
                muhammadali775@gmail.com
              </a>
            </p>
          </div>
          <div className="col-6 col-lg-3 wow fadeInUp" data-wow-delay="0.3s">
            <p className="text-muted fw-500 mb-0">Phone</p>
            <p className="text-4 text-dark fw-600 mb-0">(+1) 201 310 1256</p>
          </div>
          <div className="col-6 col-lg-3 wow fadeInUp" data-wow-delay="0.4s">
            <p className="text-muted fw-500 mb-0">From:</p>
            <p className="text-4 text-dark fw-600 mb-0">Clearwater, FL</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
