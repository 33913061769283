import React from "react";
import resumeFile from "../documents/resume.pdf";

const Resume = () => {
  const educationDetails = [
    {
      yearRange: "2021",
      title: "Full Stack Web Development Program",
      place: "Flatiron School",
      desc: "Completed an intensive, hands-on program focused on practical applications of HTML, CSS, JavaScript," + 
      "and Ruby on Rails. Developed several full-stack web applications, emphasizing responsive design, RESTful APIs, " +
      "and database integration. Gained strong foundational skills in software development, problem-solving, and " + 
      "agile methodologies.",
    },
    {
      yearRange: "2011 - 2015",
      title: "Bachelor of Science, Finance",
      place: "Rutgers University, Rutgers Business School",
      desc: "Earned a degree in Finance, with coursework covering Corporate Finance, Investment Analysis, and " + 
      "Financial Market Regulation. Engaged in various projects that applied theoretical knowledge to real-world " +
      "financial problems, enhancing analytical and quantitative skills. Participated actively in finance-focused " + 
      "student organizations.",
    }
  ];

  const experienceDetails = [
    {
      yearRange: "2022 - 2024",
      title: "Full Stack Software Engineer",
      place: "Blue Apron/ Fresh Realm",
      desc: "Developed and maintained robust software solutions within a microservices architecture, focusing on " + 
      "enhancing functionality, reliability, and scalability."
    },
    {
      yearRange: "2023 - Present",
      title: "Full Stack Software Engineer",
      place: "Cyber Quote Generator",
      desc: "Developed a Cyber Quote Generator tool to enhance the underwriting process for cybersecurity insurance " + 
      "companies, facilitating efficient collection and analysis of business information for quote generation"
    },
    {
      yearRange: "2021 - 2022",
      title: "Full Stack Developer Intern",
      place: "Meetopolis",
      desc: "Created the profile page for an online dating platform",
    },
    {
      yearRange: "2017 - 2021",
      title: "Senior Analyst",
      place: "Bank of America Merril Lynch",
      desc: "Managed project with technology team to build enhancement to internal stock application",
    },
  ];

  const skills = [
    {
      name: "Communication",
      percent: 100,
    },
    {
      name: "Web Design",
      percent: 65,
    },
    {
      name: "HTML/CSS",
      percent: 70,
    },
    {
      name: "JavaScript",
      percent: 80,
    },
    {
      name: "React JS",
      percent: 80,
    },
    {
      name: "Ruby",
      percent: 80,
    }
  ];

  return (
    <section id="resume" className="section">
      <div className="container">
        {/* Heading */}
        <p className=" text-center mb-2 wow fadeInUp">
          <span className="bg-primary text-dark px-2">Resume</span>
        </p>
        <h2 className="text-10 fw-600 text-center mb-5 wow fadeInUp">
          A summary of My Resume
        </h2>
        {/* Heading end*/}
        <div className="row g-5 mt-5">
          {/* My Education */}
          <div className="col-lg-6 wow fadeInUp">
            <h2 className="text-7 fw-600 mb-4 pb-2">My Education</h2>
            <div className="border-start border-2 border-primary ps-3">
              {educationDetails.length > 0 &&
                educationDetails.map((value, index) => (
                  <div key={index}>
                    <h3 className="text-5">{value.title}</h3>
                    <p className="mb-2">
                      {value.place} / {value.yearRange}
                    </p>
                    <p className="text-muted">{value.desc}</p>
                    <hr className="my-4" />
                  </div>
                ))}
            </div>
          </div>
          {/* My Experience */}
          <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.2s">
            <h2 className="text-7 fw-600 mb-4 pb-2">My Experience</h2>
            <div className="border-start border-2 border-primary ps-3">
              {experienceDetails.length > 0 &&
                experienceDetails.map((value, index) => (
                  <div key={index}>
                    <h3 className="text-5">{value.title}</h3>
                    <p className="mb-2">
                      {value.place} / {value.yearRange}
                    </p>
                    <p className="text-muted">{value.desc}</p>
                    <hr className="my-4" />
                  </div>
                ))}
            </div>
          </div>
        </div>
        {/* My Skills */}
        <h2 className="text-7 fw-600 mb-4 pb-2 mt-5 wow fadeInUp">My Skills</h2>
        <div className="row gx-5">
          {skills.length > 0 &&
            skills.map((skill, index) => (
              <div className="col-md-6 wow fadeInUp" key={index}>
                <p className="fw-500 text-start mb-2">
                  {skill.name}{" "}
                  <span className="float-end">{skill.percent}%</span>
                </p>
                <div className="progress progress-sm mb-4">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: skill.percent + "%" }}
                    aria-valuenow={skill.percent}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  />
                </div>
              </div>
            ))}
        </div>
        <p className="text-center mt-5 wow fadeInUp">
          <a
            className="btn btn-outline-dark shadow-none rounded-0"
            href={resumeFile}
            download
          >
            Download CV
          </a>
        </p>
      </div>
    </section>
  );
};

export default Resume;
