import React from "react";

const Services = () => {
  // services details
  const services = [
    {
      name: "Full Stack Development",
      desc: "Combining front-end and back-end technologies to create scalable and robust web applications. From " +
      "server-side logic to client-side interfaces, I deliver comprehensive solutions tailored to your business needs.",
      icon: "fas fa-laptop-code",
    },
    {
      name: "Web Application Development",
      desc: "Specializing in building dynamic and responsive web applications that provide users with seamless "+
      "interactive experiences on any device.",
      icon: "fas fa-code",
    },
    {
      name: "API Development and Integration",
      desc: "Designing and developing high-performance APIs that integrate with and enhance the capabilities of your " +
      "existing software systems, ensuring secure and efficient data exchange.",
      icon: "fas fa-network-wired",
    },
    {
      name: "Database Design and Management",
      desc:  "Offering database solutions that are optimized for performance and scalability. Expertise in both SQL " + 
      "and NoSQL databases to store and manage your critical data efficiently.",
      icon: "fas fa-database",
    },
    {
      name: "Custom Software Solutions",
      desc:  "Tailoring software to meet the unique challenges of your business. Whether it's developing new " + 
      "features, refining existing systems, or integrating third-party services, I deliver customized solutions " + 
      "that align perfectly with your business objectives.",
      icon: "fas fa-tools",
    },
    {
      name: "Auth0 Integration and Identity Management",
      desc: "Implement secure, scalable, and easy-to-manage identity solutions with Auth0 integration. Specializing in adding robust authentication and authorization features to your applications, ensuring they meet the latest security standards and provide a seamless user experience.",
      icon: "fas fa-user-shield",
    },
  ];

  return (
    <section id="services" className="section bg-light">
      <div className="container">
        {/* Heading */}
        <p className=" text-center mb-2 wow fadeInUp">
          <span className="bg-primary text-dark px-2">What I Do?</span>
        </p>
        <h2 className="text-10 fw-600 text-center mb-5 wow fadeInUp">
          How I can help your next project
        </h2>
        {/* Heading end*/}
        <div className="row gy-5 mt-5">
          {services.length > 0 &&
            services.map((service, index) => (
              <div className="col-sm-6 col-lg-4 wow fadeInUp" key={index}>
                <div className="featured-box text-center px-md-4">
                  <div className="featured-box-icon text-primary text-13">
                    {" "}
                    <i className={service.icon} />
                  </div>
                  <h3 className="text-6 fw-600 mb-3">{service.name}</h3>
                  <p className="text-muted mb-0">{service.desc} </p>
                </div>
              </div>
            ))}
        </div>
      </div>
    </section>
  );
};

export default Services;
