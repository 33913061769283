import React from "react";

const Services = () => {
  // services details
  const services = [
    {
      name: "Product Management & Strategy",
      desc: "Bridging the gap between business goals and technical execution. Expertise in defining product roadmaps, gathering user feedback, and aligning development with strategic objectives to deliver high-impact solutions.",
      icon: "fas fa-project-diagram",
    },
    {
      name: "Technical Program Management (TPM)",
      desc: "Driving complex technical initiatives from planning to execution. Specializing in coordinating software development teams, managing risk, and ensuring timely delivery of high-quality products.",
      icon: "fas fa-tasks",
    },
    {
      name: "Roadmap Planning & Execution",
      desc: "Defining clear, strategic roadmaps that align with business objectives. Balancing short-term execution with long-term vision while working cross-functionally to prioritize the right features.",
      icon: "fas fa-map",
    },
    {
      name: "Investor Pitch Decks & Fundraising Strategy",
      desc: "Helping startups and founders craft compelling pitch decks that effectively communicate their vision, market opportunity, and growth potential. Experienced in refining messaging and structuring decks for investor meetings.",
      icon: "fas fa-chart-line",
    },
    {
      name: "Leadership & Team Management",
      desc: "Experienced in managing cross-functional teams, optimizing workflows, and fostering a culture of collaboration. Whether leading engineering teams or coordinating promotional efforts, I ensure projects run smoothly and efficiently.",
      icon: "fas fa-users",
    },
    {
      name: "API Development & Integration",
      desc: "Designing and developing high-performance APIs that integrate with and enhance the capabilities of your software systems, ensuring secure and efficient data exchange.",
      icon: "fas fa-network-wired",
    },
    {
      name: "Custom Software Solutions",
      desc: "Tailoring software to meet the unique challenges of your business. Whether it's developing new features, refining existing systems, or integrating third-party services, I deliver customized solutions that align perfectly with your business objectives.",
      icon: "fas fa-tools",
    },
    {
      name: "Data Analysis & Insights",
      desc: "Transforming raw data into actionable insights. Experienced in financial data analysis and marketing performance evaluation, using engagement metrics and conversion rates to inform business and strategy decisions.",
      icon: "fas fa-chart-bar",
    },
  ];
  

  return (
    <section id="services" className="section bg-light">
      <div className="container">
        {/* Heading */}
        <p className=" text-center mb-2 wow fadeInUp">
          <span className="bg-primary text-dark px-2">What I Do?</span>
        </p>
        <h2 className="text-10 fw-600 text-center mb-5 wow fadeInUp">
          How I can help your next project
        </h2>
        {/* Heading end*/}
        <div className="row gy-5 mt-5">
          {services.length > 0 &&
            services.map((service, index) => (
              <div className="col-sm-6 col-lg-4 wow fadeInUp" key={index}>
                <div className="featured-box text-center px-md-4">
                  <div className="featured-box-icon text-primary text-13">
                    {" "}
                    <i className={service.icon} />
                  </div>
                  <h3 className="text-6 fw-600 mb-3">{service.name}</h3>
                  <p className="text-muted mb-0">{service.desc} </p>
                </div>
              </div>
            ))}
        </div>
      </div>
    </section>
  );
};

export default Services;
