import React from "react";

import Slider from "react-slick";

const Testimonials = () => {
  const reviews = [
    {
      name: "Shiraz Saeed",
      position: "Vice President- Cyber Security Product Leader at Arch Insurance Group",
      src: "images/testimonial/shiraz-sm.jpg",
      desc: "“Mo is a creative problem solver.  He listens first and thinks outside the box to come up with " +
      "solutions to help accomplish collective goals.”",
    },
    {
      name: "Victor Wu",
      position: "Full Stack Software Engineer at Fresh Realm",
      src: "images/testimonial/victor-sm.jpg",
      desc: "“Mo was an awesome team player who held himself and others to a high standard and in addition to his " +
      "strong communication skills, he'd always put his best efforts to produce high quality work. " +
      "It was amazing to have worked with Mo on delivering various projects together”",
    },
    {
      name: "Nicholas Thomson",
      position: "Technical Writer at Datadog",
      src: "images/testimonial/nick-sm.jpg",
      desc: "“Mo can solve any problem put in front of him. Whether it was JavaScript, React, or Ruby, he was always " +
      "thinking steps ahead.”"
    },
    {
      name: "Dmitry Pavluk",
      position: "Senior Software Engineer for Canopy Inc",
      src: "images/testimonial/dmtry-sm.png",
      desc: "“I have known Mo to always bring an exceptionally high level of forethought and care to any and every " + 
      "endeavor he takes on. I recommend him as a singular friend, colleague, and employee.”",
    },
    {
      name: "James Vecchio",
      position: "Senior Software Engineer at Blue Apron",
      src: "images/testimonial/james-sm.jpg",
      desc: "“I had the pleasure of working with Mo, and I can confidently say that he is the type of engineer " +
      "you want on your team. Mo approaches every challenge with a willingness to learn. His ability to adapt and " +
      "his openness to feedback make him a valuable asset to any project. Beyond his technical skills, Mo's positive" +
      "attitude is infectious and contributes greatly to the team dynamic. I highly recommend Mo for any " +
      "engineering role.”",
    },
    {
      name: "Faiyam Rahman",
      position: "Co-Founder and CEO at Blaze",
      src: "images/testimonial/faiyam-sm.jpg",
      desc: " “Mo is one of the most reliable and committed people I know. When Mo’s on the job, I know it’s going " + 
      "to get done. I know he’ll be a great addition to any engineering team!”",
    },
  ];

  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <button
      {...props}
      className={
        "slick-prev slick-arrow" + (currentSlide === 0 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === 0 ? true : false}
      type="button"
    >
      <i className="fa fa-chevron-left"></i>
    </button>
  );
  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <button
      {...props}
      className={
        "slick-next slick-arrow" +
        (currentSlide === slideCount - 1 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === slideCount - 1 ? true : false}
      type="button"
    >
      <i className="fa fa-chevron-right"></i>
    </button>
  );

  var settings = {
    dots: true,
    arrows: true,
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4500,
  };

  return (
    <section id="testimonial" className="section bg-secondary">
      <div className="container">
        {/* Heading */}
        <p className=" text-center mb-2 wow fadeIn">
          <span className="bg-primary text-dark px-2">Testimonials</span>
        </p>
        <h2 className="text-10 fw-600 text-white text-center mb-5 wow fadeIn">
          What folks are saying
        </h2>
        {/* Heading end*/}
        <div className="row">
          <div className="col-lg-9 mx-auto wow fadeInUp">
            <Slider {...settings}>
              {reviews.length > 0 &&
                reviews.map((review, index) => (
                  <div className="item text-center px-5" key={index}>
                    {" "}
                    <span className="text-9 text-primary">
                      <i className="fa fa-quote-start" />
                    </span>
                    <p className="text-5 text-white">{review.desc}</p>
                    <img
                      className="img-fluid d-inline-block w-auto rounded-circle shadow"
                      src={review.src}
                      alt={review.name}
                    />{" "}
                    <strong className="d-block text-3 fw-600 text-white">
                      {review.name}
                    </strong>{" "}
                    <span className="text-light">{review.position}</span>{" "}
                  </div>
                ))}
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
