import React from "react";
import resumeFile from "../documents/resume.pdf";

const Resume = () => {
  const educationDetails = [
    {
      yearRange: "2021",
      title: "Full Stack Web Development Program",
      place: "Flatiron School",
      desc: "Completed an intensive, hands-on program focused on practical applications of HTML, CSS, JavaScript," + 
      "and Ruby on Rails. Developed several full-stack web applications, emphasizing responsive design, RESTful APIs, " +
      "and database integration. Gained strong foundational skills in software development, problem-solving, and " + 
      "agile methodologies.",
    },
    {
      yearRange: "2011 - 2015",
      title: "Bachelor of Science, Finance",
      place: "Rutgers University, Rutgers Business School",
      desc: "Earned a degree in Finance, with coursework covering Corporate Finance, Investment Analysis, and " + 
      "Financial Market Regulation. Engaged in various projects that applied theoretical knowledge to real-world " +
      "financial problems, enhancing analytical and quantitative skills. Participated actively in finance-focused " + 
      "student organizations.",
    }
  ];

  const volunteeringDetails = [
    {
      yearRange: "2021 - Present",
      title: "Growth & Digital Campaign Lead (Volunteer)",
      place: "Isha USA",
      desc: 
      "Led and scaled digital engagement initiatives across North America, optimizing strategies for online outreach and user engagement. " + 
      "Executed data-driven campaign strategies, improving audience reach and conversion rates. " + 
      "Developed and managed growth-focused digital programs, ensuring alignment with organizational goals. " + 
      "Managed cross-functional teams of volunteers, overseeing campaign execution and strategic planning. " + 
      "Previously served as Promotion Coordinator for New York and Tampa, leading localized engagement efforts before expanding to a national role.",
    }
  ];
  
  const experienceDetails = [
    {
      yearRange: "2023 - Present",
      title: "Founder & Full Stack Software Engineer",
      place: "Riki Technologies",
      desc:
      "As a Technical Product Manager for Riki Technologies, I led product strategy and development for a platform that " +
      "automates cyber insurance underwriting, streamlining data collection, risk assessment, and quote generation. " +
      "I defined the product roadmap, prioritized features, and worked closely with engineers to execute the vision efficiently. " +
      "Managing a cross-functional team of three developers, I oversaw sprint planning, task delegation, and code reviews to ensure high-quality delivery. " +
      "The platform was built using Next.js, React, and Ruby on Rails, with secure authentication powered by Auth0 (Okta). " +
      "Optimized state management with Redux Toolkit and designed a clean, user-friendly UI with Material-UI. " +
      "Deployed via Heroku, it provides a scalable and efficient quoting experience for insurers. " +
      "This project demonstrates my ability to bridge business needs with technical execution, delivering high-impact product solutions."  
    
    },
    {
      yearRange: "2022 - 2024",
      title: "Full Stack Software Engineer",
      place: "Blue Apron/ Fresh Realm",
      desc:
      "At Blue Apron, which was later acquired by FreshRealm, I collaborated with Product Managers to define and " +
      "implement new features for logistics and fulfillment systems, ensuring alignment with business objectives. " +
      "I played a key role in sprint planning, assisting with feature estimation, backlog grooming, and roadmap alignment. " +
      "I developed full-stack solutions for packline management, optimizing scalability and efficiency within logistics workflows. " +
      "Additionally, I led API integrations to enable seamless data flow between internal logistics software and third-party systems, " +
      "improving operational efficiency. " +
      "I also enhanced system reliability and troubleshooting processes, proactively identifying and resolving backend issues " +
      "to minimize downtime and maintain smooth operations."  
    
    },
    {
      yearRange: "2017 - 2021",
      title: "Senior Analyst, Markets | Product & Data Analytics",
      place: "Bank of America Merril Lynch",
      desc:
      "Led multiple projects to enhance internal stock applications, collaborating with engineering teams to improve workflows and system functionality. " +
      "Gathered stakeholder requirements and translated them into technical specifications, ensuring seamless execution by development teams. " +
      "Managed end-to-end product enhancements, including feature prioritization, implementation, and post-launch analysis. " +
      "Analyzed large-scale financial datasets (100K+ rows) to drive data-backed product decisions and improve system efficiency. " +
      "Integrated internal tools with new division-wide software, ensuring smooth adoption and usability across teams."    
    },
  ];

  const skills = [
    // 🌟 Product & Strategy Skills
    {
      name: "Product Management",
      percent: 90,
    },
    {
      name: "Roadmapping",
      percent: 85,
    },
    {
      name: "Agile & Sprint Planning",
      percent: 90,
    },
    {
      name: "Stakeholder Management",
      percent: 95,
    },
    {
      name: "Data-Driven Decision Making",
      percent: 85,
    },
  
    // 🚀 Technical Skills (Relevant for TPM)
    {
      name: "JavaScript",
      percent: 85,
    },
    {
      name: "React JS",
      percent: 90,
    },
    {
      name: "Next.js",
      percent: 85,
    },
    {
      name: "Ruby on Rails",
      percent: 80,
    },
    {
      name: "API Development & Integration",
      percent: 85,
    },
    {
      name: "Authentication & Security (Auth0, Okta)",
      percent: 75,
    },
  
    // 📊 Leadership & Analytical Skills
    {
      name: "Stakeholder Communication",
      percent: 100,
    },
    {
      name: "Data Analysis & Insights",
      percent: 75,
    }
  ];
  

  return (
    <section id="resume" className="section">
      <div className="container">
        {/* Heading */}
        <p className=" text-center mb-2 wow fadeInUp">
          <span className="bg-primary text-dark px-2">Resume</span>
        </p>
        <h2 className="text-10 fw-600 text-center mb-5 wow fadeInUp">
          A summary of My Resume
        </h2>
        {/* Heading end*/}
        <div className="row g-5 mt-5">
          {/* My Education */}
          <div className="col-lg-6 wow fadeInUp">
            <h2 className="text-7 fw-600 mb-4 pb-2">My Education</h2>
            <div className="border-start border-2 border-primary ps-3">
              {educationDetails.length > 0 &&
                educationDetails.map((value, index) => (
                  <div key={index}>
                    <h3 className="text-5">{value.title}</h3>
                    <p className="mb-2">
                      {value.place} / {value.yearRange}
                    </p>
                    <p className="text-muted">{value.desc}</p>
                    <hr className="my-4" />
                  </div>
                ))}
            </div>
            <h2 className="text-7 fw-600 mb-4 pb-2">Volunteering</h2>
            <div className="border-start border-2 border-primary ps-3">
              {volunteeringDetails.length > 0 &&
                volunteeringDetails.map((value, index) => (
                  <div key={index}>
                    <h3 className="text-5">{value.title}</h3>
                    <p className="mb-2">
                      {value.place} / {value.yearRange}
                    </p>
                    <p className="text-muted">{value.desc}</p>
                    <hr className="my-4" />
                  </div>
                ))}
            </div>
          </div>
          {/* My Experience */}
          <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.2s">
            <h2 className="text-7 fw-600 mb-4 pb-2">My Experience</h2>
            <div className="border-start border-2 border-primary ps-3">
              {experienceDetails.length > 0 &&
                experienceDetails.map((value, index) => (
                  <div key={index}>
                    <h3 className="text-5">{value.title}</h3>
                    <p className="mb-2">
                      {value.place} / {value.yearRange}
                    </p>
                    <p className="text-muted">{value.desc}</p>
                    <hr className="my-4" />
                  </div>
                ))}
            </div>
          </div>
        </div>
        {/* My Skills */}
        <h2 className="text-7 fw-600 mb-4 pb-2 mt-5 wow fadeInUp">My Skills</h2>
        <div className="row gx-5">
          {skills.length > 0 &&
            skills.map((skill, index) => (
              <div className="col-md-6 wow fadeInUp" key={index}>
                <p className="fw-500 text-start mb-2">
                  {skill.name}{" "}
                  <span className="float-end">{skill.percent}%</span>
                </p>
                <div className="progress progress-sm mb-4">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: skill.percent + "%" }}
                    aria-valuenow={skill.percent}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  />
                </div>
              </div>
            ))}
        </div>
        <p className="text-center mt-5 wow fadeInUp">
          <a
            className="btn btn-outline-dark shadow-none rounded-0"
            href={resumeFile}
            download
          >
            Download Resume
          </a>
        </p>
      </div>
    </section>
  );
};

export default Resume;
